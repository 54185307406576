/** @module commabreak */

/** Class to introduce breaks after certain commas */
class CommaBreak {

	/**
	 * 
	 * @param {Element} container 
	 * @param {number} proportion 
	 */
	static insertBreaks( container, proportion = 0.75 ) {

		if ( 
			   !container 
			|| !container.innerText
			|| !container.innerText.indexOf
			|| ( container.innerText.indexOf( "," ) == -1 )
		) { 
			return; 
		}
		const containerBCR = container.getBoundingClientRect();
	
		container.innerHTML = container.innerHTML.replace( 
			/,/g, 
			"<comma>,</comma>" 
		);
		const commas = container.querySelectorAll( "comma" );
		for ( let c = 0, l = commas.length - 1; c <= l; c = c + 1 ) {
			const comma = commas[ c ];
			const commaBCR = comma.getBoundingClientRect();
			if ( containerBCR.height < commaBCR.height * 2 ) { 
				continue;
			}
			const commaRelativeOffsetLeft = comma.offsetLeft - container.offsetLeft;
			if ( commaRelativeOffsetLeft < proportion * containerBCR.width ) { 
				continue; 
			}
			comma.after( document.createElement( "br" ) );
		}
		container.innerHTML = container.innerHTML.replace( /<[\/]*comma>/g, "" );

	}

}

export { CommaBreak }