/** @module codeblock */

import { TabSizeSelector } from "./CodeBlock/TabSizeSelector.js";
import { WrapButton } from "./CodeBlock/WrapButton.js";
import { CopyButton } from "./CodeBlock/CopyButton.js";

/** 
 * Class to add functionality to code blocks. 
 */
class CodeBlock {

	#container = null;

	/**
	 * Select, or create and return, the container of the actions buttons for a 
	 * give code block container.
	 * @param {HTMLElement} container The container of the code block.
	 * @return {HTMLElement} the container of the actions buttons
	 */
	#getOrCreateActionsContainer( container = this.#container, elementType = "div" ) {
		let actionsContainer = container.nextElementSibling;
		if (
				 !actionsContainer
			|| !actionsContainer.classList.contains( "actions" )
			|| ( actionsContainer.tagName.toLowerCase() !== elementType )
		) {
			actionsContainer = document.createElement( elementType );
			actionsContainer.className = "actions";
			container.after( actionsContainer );
		}
		return actionsContainer;
	}

	/**
	 * Extend the functionality of a code block.
	 * @param {HTMLElement} container The container of the code block.
	 */
	constructor( container ) {

		this.#container = container;

		const actions = this.#getOrCreateActionsContainer();

		if (
			   !actions.querySelector( ".tab-size" ) 
			&& ( container.cloneNode( true ).innerText.indexOf( "\t" ) > -1 )
		) {
			const tabSizeSelector = new TabSizeSelector( container.ownerDocument );
			actions.appendChild( tabSizeSelector.element );
			tabSizeSelector.setDefaultValue();
		}

		if ( !actions.querySelector( ".wrap" ) ) {
			const wrapButton = new WrapButton( container.ownerDocument );
			actions.appendChild( wrapButton.element );
		}

		if (
			   ( !actions.querySelector( ".copy" ) )
			&& navigator 
			&& navigator.clipboard
			&& ( typeof navigator.clipboard == "object" )
		) {
			const copyButton = new CopyButton( container.ownerDocument ); 
			actions.appendChild( copyButton.element );
		}

	}

}

export { CodeBlock }