/** @module tabsizeselector */

import { ActionElement } from "./ActionElement.js"

/** 
 * Class representing a selector for the number of spaces a tabulation should occupy. 
 * @extends module:actionelement~ActionElement
 */
class TabSizeSelector extends ActionElement {

	/**
	 * Changes the class of the code block to reflect the tab size selection.
   * @memberof module:tabsizeselector~TabSizeSelector#
   * @access private
   * @function onChange
	 * @param {?Event} changeEvent 
	 * @param {TabSizeSelector} [instance=this]
	 */
	#onChange( changeEvent, instance = this ) {
		const select = instance.element.querySelector( "select" );
		const codeContainer = instance.getCodeBlockContainer();
		codeContainer.className = codeContainer.className.replace( /(^|\s*)tab-size-\d+(\s*|$)/g, " " );
		codeContainer.classList.add( "tab-size-" + select.value );
	}

	/**
	 * Tries to set the value to the one from the computed style.
	 */
	setDefaultValue() {
		const select = this.element.querySelector( "select" );
		const codeBlock = this.getCodeBlockContainer();
		const codeBlockStyles = window.getComputedStyle( codeBlock );
		const tabSize = codeBlockStyles.hasOwnProperty( "tab-size" )
			? parseInt( codeBlockStyles.tabSize )
			: 2
		;
		select.value = tabSize;
	}

	/**
	 * Create a tab-size selector.
	 * @param {Document} [document=window.document] The document object.
	 */
	constructor( document = window.document ) {
		super( document, "label", "tab-size", "Tab size (in spaces)", "&#8677; " );
		const instance = this;

		const codeBlockStyles = window.getComputedStyle( document.documentElement );
		const tabSize = codeBlockStyles.hasOwnProperty( "tab-size" )
			? parseInt( codeBlockStyles.tabSize )
			: 2
		;

		const select = document.createElement( "select" );
		for ( let n = 8; n >= 1; n = n - 1 ) {
			const option = document.createElement( "option" );
			option.value = n;
			option.text = n;
			option.selected = ( n == tabSize );
			select.prepend( option );
		}
		function onChange( changeEvent ) { instance.#onChange( changeEvent ); }
		select.addEventListener( "change", onChange );

		instance.element.appendChild( select );

	}

}

export { TabSizeSelector }