/**
 * @file Functionality for a simple blog.
 * @author Gonzalo Alvarez de Aldecoa
 */

/** @module main */

import * as GBlog from "./modules/CodeBlock.js";
import * as CB from "./modules/CommaBreak.js";

( function( window, document, navigator, _html, localStorage, matchMedia ) {

	/**
	 * Attemps to call the `preventDefault` method of `event`.
	 * @access private
	 * @function _preventDefault
	 * @param {Event} event
	 */
	function _preventDefault( event ) {
		event && event.preventDefault && event.preventDefault();
	}
	
	/**
	 * Get the parent `a` element of `element`.
	 * @access private
	 * @function _getAnchor
	 * @param {Element} element An HTML element.
	 * @returns {?Element} The parent `a` element of `element`.
	 */
	function _getAnchor( element ) {
		let anchor = element;
		while( anchor && ( anchor.tagName.toLowerCase() != "a" ) ) {
			anchor = anchor.parentElement;
		}
		return anchor;
	}
	
	/**
	 * Create a safe new window for a click event on a link.
	 * @access private
	 * @function _onExternalLinkClick
	 * @param {Event} clickEvent A click event.
	 */
	function _onExternalLinkClick( clickEvent ) {
		_preventDefault( clickEvent );
		const a = _getAnchor( clickEvent.target );
		if ( !a ) { return; }
		const newWindow = window.open();
		newWindow.opener = null;
		newWindow.location = a.href;
	}

	/**
	 * Update the document's meta data.
	 */
	function _initDocumentMeta() {
		document.title = document.querySelector( "main header .title" ).innerText;
		document.title += " @ galdecoa.com";
		// The following code is unnecessary. Its only purpose is to prevent 
		// Lighthouse pointing out there is no `description` `meta` in `head`.
		const head = document.querySelector( "head" );
		if ( head.querySelector( "meta[name=description]" ) ) { return; }
		const meta = document.querySelector( "main header meta[name=description]" );
		if ( !meta ) { return; }
		head.appendChild( meta );
	}

	_initDocumentMeta();

	/**
	 * Initialise the dark mode controls.
	 * @access private
	 * @function _initColourScheme	 
	 */
	function _initColourScheme() {

		const colorSchemeNames = [ "browser", "light", "dark" ];
		const colorSchemeIcons = [ "&#9680;", "&#9728;", "&#9789;" ];
		const numColorSchemes = colorSchemeNames.length;
		const colorSchemeClassNames = colorSchemeNames
			.map( colorSchemeName => colorSchemeName + "-color-scheme" )
		;

		_html.classList.remove( ...colorSchemeClassNames ); 

		/*/
		let preferredColorScheme = "";
		if ( matchMedia ) {
			for ( let c = numColorSchemes - 1; c >= 0; c = c - 1 ) {
				const schemeName = colorSchemeNames[ c ];
				if ( matchMedia( "(prefers-color-scheme: " + schemeName + ")" ).matches ) {
					preferredColorScheme = schemeName;
					break;
				}
			}
		}
		/**/

		let storedColorScheme = "";
		if ( localStorage ) {
			storedColorScheme = localStorage.getItem( "color-scheme" );
			if ( storedColorScheme ) {
				_html.classList.add( storedColorScheme + "-color-scheme" ); 
			}
		}

		const contrastSwitch = document.querySelector( "label.contrast" );
		if ( contrastSwitch ) {

			const defaultColorScheme = storedColorScheme
				? storedColorScheme
				: "browser"
			;

			let select = contrastSwitch.querySelector( "select" );
			if ( !select ) {
				select = document.createElement( "select" );
				contrastSwitch.prepend( select );
			}
			select.innerHTML = "";
			for ( let c = numColorSchemes - 1; c >= 0; c = c - 1 ) {
				const option = document.createElement( "option" );
				option.innerHTML = colorSchemeIcons[ c ]
					+ " " 
					+ colorSchemeNames[ c ] 
					+ " color scheme"
				;
				option.value = colorSchemeNames[ c ];
				option.selected = ( colorSchemeNames[ c ] == defaultColorScheme );
				select.prepend( option );
			}

			select.addEventListener( "change", function( changeEvent ) {
				const newColorScheme = changeEvent.target.value;
				_html.classList.remove( ...colorSchemeClassNames ); 
				_html.classList.add( newColorScheme + "-color-scheme" );
				if ( !localStorage ) { return; }
				if ( newColorScheme == "browser" ) {
					localStorage.removeItem( "color-scheme" );
					return;
				}
				localStorage.setItem( "color-scheme", newColorScheme );
			} );

			select.addEventListener( "blur", function( blurEvent ) {
				contrastSwitch.classList.remove( "open" );
			} );

			contrastSwitch.addEventListener( "click", function( clickEvent ) {
				clickEvent && clickEvent.preventDefault && clickEvent.preventDefault();
				contrastSwitch.classList.add( "open" );
				select.focus();
			} );
		}

	}

	_initColourScheme();
	
	const _externalLinks = document.querySelectorAll( "a[rel*=external]" );
	for ( let l = _externalLinks.length - 1; l >= 0; l = l - 1 ) {
		_externalLinks[ l ].addEventListener( "click", _onExternalLinkClick );
	}
	
	/**
	 * Initialise the dark mode controls.
	 * @access private
	 * @function _initCodeBlocks	 
	 */
	function _initCodeBlocks() {

		const codeBlocks = document.querySelectorAll( "div.sourceCode" );

		for ( let c = codeBlocks.length - 1; c >= 0; c = c - 1 ) {
			new GBlog.CodeBlock( codeBlocks[ c ] );
		}

	}

	_initCodeBlocks();

	function _initCommaBreaks(){

		CB.CommaBreak.insertBreaks( document.querySelector( "h1.title" ) );

		CB.CommaBreak.insertBreaks( document.querySelector( ".prev-next .next" ), 0.6 );

	};

	_initCommaBreaks();

	function _initServiceWorker() {
		
		if ( !( "serviceWorker" in navigator ) ) { return }

		function afterServiceWorkerRegisters() { 
			console.log( "Service worker registered!" ); 
		}

		function catchServiceWorkerRegisterError( error ) {
			console.warn('Error registering service worker:');
			console.warn(error);
		}

		navigator.serviceWorker.register( "./sw.js" )
			.then( afterServiceWorkerRegisters )
			.catch( catchServiceWorkerRegisterError )
		;

	}

	_initServiceWorker();

} )( window, document, navigator, document.documentElement, window.localStorage, window.matchMedia );