/** @module wrapbutton */

import { ActionElement } from "./ActionElement.js"

/** 
 * Class representing a button to change a code block's line-wrap state. 
 * @extends module:actionelement~ActionElement
 */
class WrapButton extends ActionElement {

	/**
	 * Changes the class of the button and the code block to reflect the line-wrap state.
   * @memberof module:wrapbutton~WrapButton#
   * @access private
   * @function onClick
	 * @param {Event} clickEvent 
	 * @param {WrapButton} [instance=this]
	 */
	#onClick( clickEvent, instance = this ) {
		instance.preventDefault( clickEvent );
		instance.element.classList.toggle( "off" );
		const codeContainer = instance.getCodeBlockContainer();
		const addOrRemoveFunctionName = instance.element.classList.contains( "off" )
			? "remove"
			: "add"
		;
		codeContainer.classList[ addOrRemoveFunctionName ]( "wrap-lines" );
	}

	/**
	 * Create a button to change a code block's line-wrap state.
	 * @param {document} [document=window.document]
	 */
	constructor( document = window.document ) {
		super( document, "button", "wrap off", "Line wrap on/off", "&#9166;" );
		const instance = this;
		function onClick( clickEvent ) { instance.#onClick( clickEvent ); }
		instance.element.addEventListener( "click", onClick );
	}

}

export { WrapButton }