/** @module copybutton */

import { ActionElement } from "./ActionElement.js"

/** 
 * Class representing a button to copy the contents of a code block to the clipboard. 
 * @extends module:actionelement~ActionElement
 */
class CopyButton extends ActionElement {

	/**
	 * Copies the contents of a code block to the clipboard.
   * @memberof module:copybutton~CopyButton#
   * @access private
   * @function onClick
	 * @param {Event} clickEvent 
	 * @param {CopyButton} [instance=this]
	 */
	#onClick( clickEvent, instance = this ) {
	
		instance.preventDefault( clickEvent );
		let elementPN = "element"; // minification pre-optimisation

		let codeContainer = instance.getCodeBlockContainer();
		let code = codeContainer ? 
			codeContainer.cloneNode( true ).innerText.trim() 
			: ""
		;
		codeContainer = null; // garbage collection hint

		if ( !code ) { return; }

		function removeCopiedStatus() {
			instance[ elementPN ].classList.remove( "copied" ); 
			instance[ elementPN ].removeAttribute( "data-timer-id" );
			elementPN = null; // garbage collection hint
		}

		function resetOrCreateTimerToRemoveCopiedStatus( timeOut = 2500 ) {
			let timerId = instance[ elementPN ].getAttribute( "data-timer-id" );
			if ( timerId ) {
				clearTimeout( timerId );
			}
			timerId = setTimeout( removeCopiedStatus, timeOut );
			instance[ elementPN ].setAttribute( "data-timer-id", timerId );
		}

		function onWriteTextSuccess() {
			instance[ elementPN ].classList.add( "copied" );
			resetOrCreateTimerToRemoveCopiedStatus();
		}

		function onWriteTextError() {
			elementPN = null; // garbage collection hint
		}

		navigator.clipboard
			.writeText( code )
			.then( onWriteTextSuccess, onWriteTextError )
		;

		code = null; // garbage collection hint

	}

	/**
	 * Create a button to copy the contents of a code block to the clipboard.
	 * @param {document} [document=window.document]
	 */
	constructor( document = window.document ) {
		super( document, "button", "copy", "Copy to clipboard", "&#128203;" );
		const instance = this;
		function onClick( clickEvent ) { instance.#onClick( clickEvent ); }
		instance.element.addEventListener( "click", onClick );
	}

}

export { CopyButton }