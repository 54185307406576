/** @module actionelement */

/** Class representing an action element bound to a code block */
class ActionElement {

	/**
     * @memberof module:actionelement~ActionElement#
     * @member {?Element} */ 
	element = null;

	/**
	 * Attemps to call the `preventDefault` method of `event`.
	 * @param {Event} event An event with a `preventDefault` method.
	 */
	preventDefault( event ) {
		event && event.preventDefault && event.preventDefault();
	}

	/**
	 * Get the container element for a code block action.
	 * @param {Element} [element=this.element] A code block action element.
	 * @param {String} [containerElementType=div] The element type of the container.
	 * @returns {?Element} The container for the code block action button.
	 */
	getActionsContainer( element = this.element, containerElementType = "div" ) {
		if ( !element ) { return null; }
		let actionsContainer = element.parentElement;
		while (
				 actionsContainer
			&& ( actionsContainer.tagName.toLowerCase() !== containerElementType )
			&& !actionsContainer.classList.contains( "actions" )
		) {
			actionsContainer = actionsContainer.parentElement;
		}
		return actionsContainer;
	}

	/**
	 * Get the code block before the action element's container.
	 * @param {Element} [element=this.element] A code block action element.
	 * @returns {?Element} The code block before the action element's container.
	 */
	getCodeBlockContainer( element = this.element ) {
		const actionsContainer = this.getActionsContainer( element );
		return ( actionsContainer && actionsContainer.previousElementSibling );
	}

	/**
	 * Create a code block action element.
	 * @param {!Document} document The document object.
	 * @param {!String} elementType The type of element to create.
	 * @param {String} [className] The class attribute string.
	 * @param {String} [title] The title string.
	 * @param {String} [innerHTML] The HTML contents of the element.
	 */
	constructor( document, elementType, className = "", title = "", innerHTML = "" ) {
		const element = document.createElement( elementType );
		element.className = className;
		element.title = title;
		element.innerHTML = innerHTML;
		this.element = element;
	}

}

export { ActionElement }